<template>
    <div class="template-page yellow-step">
        <basic-page :pagedata="this.pageData" :bannerimg="this.eventImage">
            <div class="person-header step-big">
                <div class="start">
                    <div class="label">FROM</div>
                    <div class="date" v-if="this.eventData.attributes">{{ this.newDateFrom + ' - ' + this.startHour }}</div>
                </div>
                <div class="end">
                    <div class="label">TO</div>
                    <div class="date" v-if="this.eventData.attributes">{{ this.newDateTo + ' - ' + this.endHour }}</div>
                </div>
                <div class="location">
                    <div class="label">LOCATION</div>
                    <div class="loc" v-if="this.eventData.attributes">{{ this.eventData.attributes.field_event_location }}</div>
                </div>
            </div>
            <div class="content" v-if="this.eventData && this.eventData.attributes" v-html="this.eventData.attributes.field_event_description.processed">
            </div>

            <!-- <br>
            <br>
            <br>

            <div class="video-container" v-if="eventData && eventData.attributes && eventData.attributes.field_youtube_video">
                <iframe allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                frameborder="0"
                class="video" 
                :src="'https://www.youtube.com/embed/' + this.eventData.attributes.field_youtube_video">
                </iframe>
            </div> -->

            <br>

            <h3 v-if="this.eventData.field_event_attachment && this.eventData.field_event_attachment.length">Attachment/s</h3>
            <div v-if="eventData.field_event_attachment && eventData.field_event_attachment.length">
                <div v-for="(attachment, index) in eventData.field_event_attachment" :key="index">
                    <a v-if="eventData.relationships.field_event_attachment.data[index].meta.description"
                        class="cta attachment" :href="fileSrc(attachment)" target="_blank" download>
                        {{ eventData.relationships.field_event_attachment.data[index].meta.description }}</a>
                    <a v-else class="cta attachment" :href="fileSrc(attachment)" target="_blank" download>
                        {{ attachment.attributes.filename }}
                    </a>
                </div>
            </div>


            <!--<br>
                <br>
                <div class="banner">
                    <img class="page-image" v-if="this.eventData.field_event_image" :src="imgsrc" alt="">
                </div>
            -->

            <br>
            <div class="back-button">
                <router-link to="/events-for-the-public" class="cta-link">
                    <span>Back to Events</span>
                </router-link>
            </div>
        </basic-page>
    </div>
</template>


<script>
import BasicPage from '../BasicPage.vue'
import { fetchNodes, fetchSingleNode } from '../../libs/drupalClient';
import { convertMonthShort } from '../../libs/utils'

export default {
    name: 'event',
    components: {
        BasicPage
    },
    data: () => {
        return {
            pageData: {
                title: '',
                body: {
                    summary: ''
                }
            },
            introimb: '',
            introimgbannerID: '582f3cd5-90fd-4c23-a502-2a6b90c9a533',
            eventData: {}
        }
    },
    computed: {
        eventImage() {
            return process.env.VUE_APP_ENDPOINT + (this.eventData.field_event_image ? this.eventData.field_event_image.attributes.uri.url : this.introimg)
        },
        newDateFrom() {
            /*return reWriteDate(this.eventStartDate.getFullYear() + '-' +
                (this.eventStartDate.getMonth()<10 ? ('0'+this.eventStartDate.getMonth()) : this.eventStartDate.getMonth()) + '-' + 
                this.eventStartDate.getDate())*/

            var d = this.eventStartDate
            return d.getDate() + ' ' + convertMonthShort(d.getMonth()) + ' ' + d.getFullYear()
        },
        newDateTo() {
            /*return reWriteDate(this.eventEndDate.getFullYear() + '-' +
                (this.eventEndDate.getMonth()<10 ? ('0'+this.eventEndDate.getMonth()) : this.eventEndDate.getMonth()) + '-' + 
                this.eventEndDate.getDate())*/

            var d = this.eventEndDate
            return d.getDate() + ' ' + convertMonthShort(d.getMonth()) + ' ' + d.getFullYear()
        },
        eventNID() {
            return this.$route.params.nid
        },
        eventStartDate() {
            if(this.eventData.attributes)
                return new Date(Date.parse(this.eventData.attributes.field_event_start_date))
            else return new Date()
        },
        eventEndDate() {
            if(this.eventData.attributes)
                return new Date(Date.parse(this.eventData.attributes.field_event_end_date))
            else return new Date()
        },
        startHour() {
            let d = new Date(Date.parse(this.eventData.attributes.field_event_start_date))
            let h = d.getHours()
            let m = d.getMinutes()==0 ? '00' : d.getMinutes().toString()
            if(h>12) h = (h - 12).toString() + ':' + m + ' pm'
            else h = h.toString() + ':' + m + ' am'
            return h
        },
        endHour() {
            let d = new Date(Date.parse(this.eventData.attributes.field_event_end_date))
            let h = d.getHours()
            let m = d.getMinutes()==0 ? '00' : d.getMinutes().toString()
            if(h>12) h = (h - 12).toString() + ':' + m + ' pm'
            else h = h.toString() + ':' + m + ' am'
            return h
        }
    },
    methods: {
        fileSrc(att) {
            return process.env.VUE_APP_ENDPOINT + att.attributes.uri.url
        },
        fetchevent() {
            if(this.eventNID) {
                fetchSingleNode('page', this.introimgbannerID, { 
                    include: ['field_banner_top'] }
                ).then(res => {
                    if(res[0].field_banner_top) this.introimg = res[0].field_banner_top.attributes.uri.url
                })

                fetchNodes('event', {
                    filters: [{
                        key: 'drupal_internal__nid',
                        value: this.eventNID
                    }],
                    include: ['field_event_image', 'field_event_attachment']
                }).then(res => {
                    this.eventData = res[0]
                    //console.log("this.eventData", this.eventData.field_event_attachment)
                    this.pageData.title = this.eventData.attributes.title
                    //this.pageData.body[0].summary = this.eventData.attributes.field_event_data
                })
            }   
        },
    },
    mounted() {
        this.fetchevent()
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";


.attachments {
    >div{
        margin: 0px 0px 10px 0px;
    }
}

.banner {
    width: 100%;
    max-height: 600px;

    .page-image {
        width: 100%;
        max-height: 600px;
        object-fit: contain;

    }
}

h2 {
    color: $secondaryColor;
}
.person-header {
    background: $mainColor;

    display: flex; display: -webkit-flex;
    flex-direction: row;

    > div {
        width: 250px;
    }

    .start, .end {
        position: relative;
        .label {
            font-size: .9em;
        }
        .date {
            font-weight: 700;
        }
    }

    .start {
        margin-left: 70px;
        &::before {
            content: '';
            background-image: url('../../assets/icon-calendar.svg');
            width: 30px;
            height: 30px;
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-block;
            position: absolute;
            left: -50px;
            top: .35em;
        }
    }

    .location {
        margin-left: 6rem;
        position: relative;
        .label {
            font-size: .9em;
        }
        .loc {
            font-weight: 700;
        }
    }

    .location {
        &::before {
            content: '';
            background-image: url('../../assets/icon-pin.svg');
            width: 24px;
            height: 29px;
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-block;
            position: absolute;
            left: -50px;
            top: .35em;
        }
    }

}

@media (max-width: 992px) {
    .person-header .location::before {
        left: -33px;
    }
    .person-header .end{
        margin-left: 30px ;
    }
    .start, .end, .location {
        margin-bottom: 20px;
    }

}

@media (max-width: 768px) {
    body .main-content .container > div:first-child.step-big,
    .step-big {
        padding: 40px 20px 10px 20px;
    }
    .person-header  {
        display: block;
    }
    .person-header .location::before {
        left: -39px;
    }
    .content {
        margin-top: 2rem;
    }
    .person-header .location,
    .person-header .end,
    .person-header .start {
        margin-left: 50px;
    }
}
</style>